// This code was written by an AI assistant
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import SearchBar from './home/searchBar';
import { FaSearch } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";

const Navbar = styled.nav`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    background-color: #292220;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
    z-index: 10;
    overflow-y: visible;
    overflow-x: clip;
`;

const NavBarStatic = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
    flex-wrap: nowrap;

    @media (max-width: 800px) {
        flex-wrap: wrap;
    }
`;

const FlexRowSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const FlexRowCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
`;

const NavbarBrand = styled(Link)`
    white-space: normal;
    text-align: center;
    word-break: break-all;
`;

const NavbarToggler = styled.button`
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NavLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    font-family: Montserrat, sans-serif;
    margin: 0 1.5rem;
    text-align: center;

    &:hover {
      color: #ddd;
    }
`;

const Dot = styled.div`
    height: 5px;
    aspect-ratio: 1;
    background-color: #fff;
    border-radius: 50%;
`;

const Button = styled.button`
    padding: 10px;
    font-size: 1.25rem;
    text-align: center;
    text-wrap: nowrap;
    border: none;
    border-radius: 20px;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    margin: 0.5rem;
    transition: 0.2s ease-in-out;

    &.btn-primary {
      color: white;
      background-color: var(--RoseRed);
      
        @media (max-width: 800px) {
          height: 60px;
          font-size: 15px;
        }

      height: 80px;
    }

    &.btn-secondary {
      color: white;
      background-color: var(--CadmiumOrange);

        @media (max-width: 800px) {
          height: 60px;
          font-size: 15px;
        }

      height: 80px;
    }

    &:hover {
      background-color: var(--NiceBlue);
    }
`;

const NavBarCollapsed = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: var(--ZeusBrown);
    transition: 0.4s ease-in-out;
    overflow: hidden;
    background-color: var(--ZeusBrown);
    top: 100%;
    z-index: -1;
`;

const SearchCollapse = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: var(--ZeusBrown);
    transition: 0.4s ease-in-out;
    overflow: ${({ hideOverflow }) => (hideOverflow ? 'hidden' : 'visible')};
    background-color: var(--ZeusBrown);
`;

const rotate360 = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const rotate_animation = props => css`
    ${rotate360} 0.4s;
`;

const RotatingSearchIcon = styled(FaSearch)`
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    animation: ${({ isRotating }) => isRotating && rotate_animation};
`;

export const NavMenu = () => {
    const [defaultNavBarVisible, setDefaultNavBarVisible] = useState(false)

    const [navBarVisible, setNavBarVisible] = useState();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [searchBarOpen, setSearchBarOpen] = useState(false);
    const [isRotating, setIsRotating] = useState(false);

    const toggleSearchBar = () => {
        setSearchBarOpen(!searchBarOpen)
        setIsRotating(true);
        setTimeout(() => {
            setIsRotating(false);
        }, searchBarOpen ? 0 : 400);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setDefaultNavBarVisible(window.innerWidth > 800);
        };

        setDefaultNavBarVisible(window.innerWidth > 800);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Navbar>
            <NavBarStatic>
                <FlexRowSpaceBetween>
                    <NavbarBrand to="/">
                        <img src="logoBrownFixed.png" alt="logo" />
                    </NavbarBrand>
                    {windowWidth > 500 ? <SearchBar /> : (
                        <RotatingSearchIcon onClick={toggleSearchBar} isRotating={isRotating} />
                    )}

                    <NavbarToggler onClick={() => setNavBarVisible(navBarVisible === undefined ? !defaultNavBarVisible : !navBarVisible)}>
                        <AiOutlineMenu />
                    </NavbarToggler>
                </FlexRowSpaceBetween>
                {windowWidth < 500 &&
                <SearchCollapse isOpen={searchBarOpen}>
                    <SearchBar />
                </SearchCollapse>}
                <FlexRowCenter>
                    <a href="https://paylink.paytrace.com/?m=eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMTI4R0NNIn0.0nTSF5WsS6qMe9HI2_SSOpVCb00kpNLx.KrMaKbCPnH8ff53S.-XMj0ilD1gCU7_8nIwGF6LoJLTWxXQYO_AdIXTSQ2MNIPW1gAYjVCi_foEHbaKO4iIYasA-bx49eW5fjSCqDhBrgj3sSjP7zsj9XWKSQ-rw35AyoDEfa9TCGL3nGQlEZWgEh_8hEeAb5SWH21HTqOCq9eAhGoUNmYV6GiZ8tezK21cihQRZJlqmLBefn3qCsq3IyfSJz8V3Mn1Ma14KgS7ptvGSDy8Abg4al9l91F9YIMhYzLisHWwoO8nyob-rqs1blzI_neMaVti-xaVHve0j9yXmxbFenkdIAZA.uhgfN4EQ9hRRlaC8Wk7lyA&amount=&invoice=" target="_blank" rel="noreferrer">
                        <Button className="btn-secondary">
                            Online Pay Portal
                        </Button>
                    </a>

                    <a href="https://energysource.energylab.com/" target="_blank" rel="noreferrer">
                        <Button className="btn-primary">
                            Existing Customers <br />
                            Energy Source
                        </Button>
                    </a>
                </FlexRowCenter>
            </NavBarStatic>

            {(navBarVisible === undefined ? defaultNavBarVisible : navBarVisible) ? 
                <NavBarCollapsed>
                    <NavLink to="/services">What We Do</NavLink>
                    <Dot />
                    <NavLink to="/documents-certifications">Documents and Certifications</NavLink>
                    <Dot />
                    <NavLink to="/contact-us">Contact Us</NavLink>
                    <Dot />
                    <NavLink to="/locations">Locations</NavLink>
                    <Dot />
                    <NavLink to="/FAQ">FAQ</NavLink>
                    <Dot />
                    <NavLink to="/helpful-links">Helpful Links</NavLink>
                    <Dot />
                    <NavLink to="/about-us">About Us</NavLink>
                </NavBarCollapsed>
            : <></>}
        </Navbar>
    );
};