import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from './CopyToClipboard';
import './ContactUs.css';

const ContactUs = () => {
    const [contactInfo, setContactInfo] = useState([]);
    const [locations, setLocations] = useState([]);

    const formSetup = () => {
        let contactForm = document.getElementById("contactForm");

        contactForm.addEventListener("submit", async (e) => {
            e.preventDefault();

            let customer = document.getElementById("customer");
            let organization = document.getElementById("organization");
            let email = document.getElementById("email");
            let phone = document.getElementById("phone");
            let locationElement = document.getElementById("locations");
            let requestType = document.getElementById("requests");
            let comment = document.getElementById("comments");

            if (customer.value === "" || organization.value === "" || email.value === "" || phone.value === "" || location === "" || requestType === "" || comment === "") {
                alert("Ensure you input a value in all fields!");
            } else {
                let from = "no-reply@energylab.com";
                let subject;
                let body;
                
                var response = await fetch(process.env.REACT_APP_APILink + "/api/Locations/" + locationElement.value);
                var location = await response.json();
                
                subject = `ELI - ${location.name} Form`;
                
                body = `<b>Name: </b>${customer.value}<br /><b>Organization: </b>${organization.value}<br /><b>Email: </b>${email.value}<br /><b>Phone: </b>${phone.value}<br /><br /><b>Comments / Message: </b><br />${comment.value}`;

                SendEmail({
                    from: from,
                    to: location.orderEmail,
                    subject: subject,
                    body: body
                });

                var to = email.value;
                subject = "Energy Laboratories Contact Confirmation";
                body = "Thanks for contacting us! We will get in touch with you shortly.";

                SendEmail({
                    to: to,
                    from: from,
                    subject: subject,
                    body: body
                })

                const alertMessage = document.createElement('div');
                alertMessage.classList.add('alert');
                alertMessage.textContent = 'Form submitted successfully!';

                document.body.appendChild(alertMessage);

                setTimeout(() => {
                    alertMessage.remove();
                }, 3000);

                customer.value = "";
                organization.value = "";
                email.value = "";
                phone.value = "";
                location.value = "";
                requestType.value = "";
                comment.value = "";
            }
        });
    }

    useEffect(() => {
        formSetup();
        fetchData();
        
        fetch(process.env.REACT_APP_APILink + "/api/Locations").then(response => response.json()).then(responseData => setLocations(responseData));

        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    const fetchData = async () => {
        const response = await fetch(process.env.REACT_APP_APILink + "/api/ContactInfo");
        const data = await response.json();
        setContactInfo(data);
    };

    const SendEmail = async (information) => {
        let message = {
            from: information.from,
            to: information.to,
            subject: information.subject,
            body: information.body
        }
        fetch(process.env.REACT_APP_APILink + "/api/Email", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        })
    }

    return (
        <div>
            <div className="page">
                <div className="contact">
                    <form className='data' id='contactForm'>
                        <div className="top">
                            <b className="top-title">Have A Question</b>
                            <p className="top-sub">Ask Us!</p>
                        </div>
                        <div className="info-comment">
                            <div className="info">
                                <input type="text" className="client space" id="customer" name="customer" placeholder="Name"></input>
                                <input type="text" className="client space" id="organization" name="organization" placeholder="Organization"></input>
                                <input type="text" className="client space" id="email" name="email" placeholder="Email"></input>
                                <input type="text" className="client space" id="phone" name="phone" placeholder="Phone Number"></input>
                                <select className="client space" id="locations" name="locations">
                                    <option value="" selected disabled>Location</option>
                                    {locations.map(l => 
                                        (
                                            <option value={l.id}>{l.name}</option>
                                        )
                                    )}
                                </select>
                                <select className="client" id="requests" name="requests">
                                    <option value="" selected disabled>Type of Request</option>
                                    <option value="Order Problem">Having trouble ordering?</option>
                                    <option value="Question">Have a question?</option>
                                </select>
                            </div>
                            <div className="comment">
                                <textarea id="comments" className="comments-text" placeholder="Message / Comment" style={{resize: 'none'}}></textarea>
                            </div>
                        </div>
                        <div className="submit-div">
                            <button className="submit" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
                <div className="more-info">
                    <iframe title="map" src="https://www.google.com/maps/d/u/0/embed?mid=1LOpd5E29qA2FpZgArBg4dei27ZTNibU&ehbc=2E312F&noprof=1" width="640" height="600" className="image"></iframe>
                    <p>To report an issue with your order, view Safety Data Sheets, or let us know how we are doing, click the branch location below:</p>
                    <ul>
                        {locations.map(l => {
                            return (
                                <li><Link to={`/Locations#${l.name}`}>{l.name} Lab</Link></li>
                            );
                        })}
                    </ul>
                    <p>For questions or testing needs for Texas, please contact one of the following:</p>
                    <ul>
                        {contactInfo && contactInfo.map((contact, index) => {
                            return (
                                <li key={index}><CopyToClipboard displayText={contact.name + " (" + contact.phone + ") - " + contact.email} textToCopy={contact.email} className="bullet" /></li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div className="report">
                <a href="https://secure.ethicspoint.com/domain/media/en/gui/81602/index.html" target="_blank" rel="noreferrer">Report a Concern</a>
            </div>
        </div>
    );
}

export default ContactUs;