import React, { useEffect, useState } from 'react';
import { AccordionBox } from './Accordion';
import { Document } from './Document';

let DocumentCategories;

const makeAccordion = () => {
    var acc = document.getElementsByClassName("accordion");
    var upArrow = "/Logos/up.png";
    var downArrow = "/Logos/down.png";

    for (let i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function () {
            for (let j = 0; j < acc.length; j++) {
                if (acc[j].classList.contains("active")) {
                    acc[j].classList.toggle("active");
                    var arrow = acc[j].getElementsByTagName('img');
                    if (arrow[0].src.includes(upArrow)) {
                        arrow[0].src = downArrow;
                    }
                    var panel = acc[j].nextElementSibling;
                    if (panel.style.maxHeight) {
                        panel.style.maxHeight = null;
                        if (this === acc[j]) {
                            return;
                        }
                    }
                }
            }
            this.classList.toggle("active");
            var arrow = this.getElementsByTagName('img');
            if (arrow[0].src.includes(downArrow)) {
                arrow[0].src = upArrow;
            } else {
                arrow[0].src = downArrow;
            }
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
    }
};

const Documents = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    useEffect(() => {
        makeAccordion();
    });

    const fetchData = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_APILink + "/api/DocumentCategories", {
                method: 'GET'
            });
            const data = await response.json();
            DocumentCategories = data;
            setData(DocumentCategories);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const makePage = () => {
        if (!data) return null;

        return data.map(category => {
            const documents = category.documents.sort((a, b) => 
                a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
            ).map(document1 => (
                <li key={document1.id}>
                    <Document id={`${process.env.REACT_APP_APILink}/api/DocumentCategories/Download/${document1.id}/${document1.name}`} text={document1.name} />
                </li>
            ));

            return (
                <AccordionBox key={category.id} title={category.name} content={
                    <ul className="offset">
                        {documents}
                    </ul>
                } />
            );
        });
    };

    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <div className="page-padding">
            <br />
            <h2 className="montserrat-regular dark"><b>Documents & Certifications</b></h2>
            <p className="dark">Click on one of the categories below to view all of the documents in that category.</p>
            <br />
            {makePage()}
        </div>
    );
};

export default Documents;