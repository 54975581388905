// BottleOrder.js
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { repeatOrderEmailTemplate, newOrderEmailTemplate, newQuoteEmailTemplate } from './EmailTemplates';
import { useForm, Controller, set } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 2rem;
    gap: 1rem;


    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

const DataContainer = styled.div`
    padding: 1rem;
    max-width: 800px;
    background-color: var(--ZeusBrown);
    color: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px 5px rgba(0, 0, 0, 0.1);
`;

const OrdersContainer = styled.div`
    background-color: #ffe8d4;
    color: black;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
`;

const StickyRunway = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
`;

const AdditionalInfoContainer = styled.div`
    position: sticky;
    bottom: 1rem;
    padding: 1rem;
    background-color: var(--ZeusBrown);
    color: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px 5px rgba(0, 0, 0, 0.1);
    max-width: 400px;

    @media (max-width: 600px) {
        display: none;
    }
`;

const MobileAdditionalInfoContainer = styled.div`
    color: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px 5px rgba(0, 0, 0, 0.1);

    @media (min-width: 600px) {
        display: none;
    }
`;


const InputWrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.wide ? 'row' : 'column')};
    justify-content: space-between;
    ${props => (props.wide ? 'align-items: center;' : '')}
    ${props => (props.wide ? 'gap: 10px;' : '')}
    border: ${(props) => props.border || 'none'};

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0;
    }
`;

const RadioInputWrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.wide ? 'row' : 'column')};
    justify-content: ${(props) => (props.wide ? 'flex-start' : 'space-between')};
    ${props => (props.wide ? 'align-items: center;' : '')}
    ${props => (props.wide ? 'gap: 10px;' : '')}
    margin-bottom: 10px;
    max-width: 100%;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const Label = styled.label`
    margin-bottom: 5px;
    font-weight: bold;
`;

const Input = styled.input`
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    ${props => (props.wide ? 'flex-grow: 1;' : 'width: max-content;')}
    outline: ${(props) => props.outline || 'none'};

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const RadioInput = styled.input`
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    gap: 5px;
`;

const RadioGroup = styled.div`
    display: flex;
    ${props => (props.wide ? '' : 'flex-direction: column;')}
    outline: ${(props) => props.outline || 'none'};\
    border-radius: 5px;

    gap: 10px;
    max-width: 100%;

    @media (max-width: 600px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
`;

const SubmitButton = styled.button`
    background-color: var(--CadmiumOrange);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 1.5rem;
    padding: 10px 2rem;

    &:hover {
        background-color: #e57321;
    }

    &:active {
        background-color: #ff9800;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const TwoColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

const ThreeColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

const ResizingTextInput = styled(TextareaAutosize)`
    display: block;
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
`;

const RequiredIndicator = styled.span`
    color: #e11e34;
    margin-left: 3px;
    cursor: help;
`;

const SelectInput = styled.select`
    display: block;
    outline: ${(props) => props.outline || 'none'};
    border-radius: 5px;
`;

const AddScheduleButton = styled.button`
    background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    padding: 5px 10px;

    &:hover {
        background-color: #4caf50;
    }
`;

const RemoveScheduleButton = styled.button`
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    padding: 5px 10px;

    &:hover {
        background-color: #f44336;
    }
`;

const InputField = ({ label, type, name, required, register, watch, submitAttempts, wide = false, fullInput = true }) => {
    const [outline, setOutline] = useState('none');
    const [touched, setTouched] = useState(false);

    let value;

    if (watch) {
        value = watch(name);
    }

    useEffect(() => {
        if (required && (value === '' || value === undefined) && (touched || submitAttempts > 0)) {
            setOutline('2px solid var(--RoseRed)');
        } else if (value !== '' && value !== undefined) {
            setTouched(true);
            setOutline('none');
        }
    }, [value, required, submitAttempts]);

    return (
        <InputWrapper wide={wide}>
            <Label htmlFor={name} title={required ? "Required Field" : null}>
                {label}
                {required && <RequiredIndicator title='Required Field'>*</RequiredIndicator>}
            </Label>
            <Input type={type} id={name} wide={fullInput} {...register(name, { required })} outline={outline} />
        </InputWrapper>
    )
};

const ResizeableInputField = ({ name, placeholder, control }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
                <ResizingTextInput
                    {...field}
                    placeholder={placeholder}
                    minRows={3}
                    maxRows={57}
                />
            )}
        />
    )
};

const RadioField = ({ label, name, options, required, watch, submitAttempts, register, wide = false, horizontalInputs = true }) => {
    const [outline, setOutline] = useState('none');

    let value;

    if (watch) {
        value = watch(name);
    }

    useEffect(() => {
        if (required && value == undefined && submitAttempts > 0) {
            setOutline('2px solid var(--RoseRed)');
        } else {
            setOutline('none');
        }
    }, [value, required, submitAttempts]);


    return (
        <RadioInputWrapper wide={wide}>
            <Label htmlFor={name} title={required ? "Required Field" : null}>
                {label}
                {required && <RequiredIndicator title='Required Field'>*</RequiredIndicator>}
            </Label>
            <RadioGroup wide={horizontalInputs} outline={outline}>
                {options.map((option) => (
                    <RadioLabel key={option.key === undefined ? option : option.key}>
                        <RadioInput type="radio" {...register(name, { required })} value={option.key === undefined ? option : option.key} />
                        {option.value == undefined ? option : option.value}
                    </RadioLabel>
                ))}
            </RadioGroup>
        </RadioInputWrapper>
    )
};

const SelectField = ({ label, name, options, required, register, watch, submitAttempts }) => {
    const [outline, setOutline] = useState('none');
    const [touched, setTouched] = useState(false);

    const value = watch(name);

    useEffect(() => {
        if (required && (value == undefined || value == '') && (touched || submitAttempts > 0)) {
            setOutline('2px solid var(--RoseRed)');
        } else if (value !== '' && value !== undefined) {
            setTouched(true);
        } else {
            setOutline('none');
        }
    }, [value, required, submitAttempts]);

    return (
        <>
            <Label htmlFor={name} title={required ? "Required Field" : null}>
                {label}
                {required && <RequiredIndicator title='Required Field'>*</RequiredIndicator>}
            </Label>
            <SelectInput {...register(name, { required })} outline={outline} defaultValue={""}>
                <option value="" disabled>Select...</option>
                {options.map((option) => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </SelectInput>
        </>
    )
};

const ThankYou = () => (
    <Container>
        <DataContainer>
            <h1 className="montserrat-regular"><b>Thank You!</b></h1>
            <p>Your form has been submitted successfully. We will be in touch with you shortly.</p>
        </DataContainer>
    </Container>
);

export const NewQuote = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitAttempts, setSubmitAttempts] = useState(0);
    const [scheduleNameCount, setScheduleNameCount] = useState(1);
    const [supportEmail, setSupportEmail] = useState('');

    useEffect(() => {
        fetch(process.env.REACT_APP_APILink + "/api/WebsiteConfig", {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                setSupportEmail(data["supportEmail"]);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const { register, handleSubmit, watch, formState: { errors }, control } = useForm({
        defaultValues: {
            date: new Date().toISOString().split('T')[0],
        },
    });

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCAPTCHASubmit = useCallback(async (form_data) => {
        if (!process.env.REACT_APP_CAPTCHA_SITE_KEY) {

            onSubmit(form_data);
            return;
        }

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');
        fetch(process.env.REACT_APP_APILink + '/api/Captcha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ token: token })
        }).then(data => {
            if (data.status === 200)
                onSubmit(form_data);
            else {
                alert('Error submitting CAPTCHA. Please confirm your humanity and try again.');
            }
        }).catch(err => {
            alert('Error submitting form. Please try again later.');
        });
    }, [executeRecaptcha]);


    const onSubmit = async (data) => {

        const scheduleNames = [];
        for (let i = 1; i <= scheduleNameCount; i++) {
            const scheduleName = data[`scheduleName${i}`];
            if (scheduleName) {
                scheduleNames.push(scheduleName);
            }
        }
        const scheduleNamesString = scheduleNames.join(', ');

        data.scheduleNames = scheduleNamesString;
        
        const from = 'no-reply@energylab.com';
        const subject = 'New Bottle Order - ' + data.contact;
        const body = newQuoteEmailTemplate(data);

        const email = {
            from: from,
            subject: subject,
            body: body,
            to: supportEmail
        };

        SendEmail(email);
    };

    const SendEmail = (message) => {
        fetch(process.env.REACT_APP_APILink + '/api/Email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        }).then(() => {
            setFormSubmitted(true);
        }).catch(err => {
            alert('Error submitting form. Please try again later.');
        });
    };

    if (formSubmitted) {
        return <ThankYou />;
    }

    return (
        <Container>
            <DataContainer>
                <h1 className="montserrat-regular"><b>Request Quote</b></h1>
                <form id="bottleOrderForm" onSubmit={handleSubmit(handleReCAPTCHASubmit)}>
                    <OrdersContainer>
                        <h2>Client Information</h2>
                        <TwoColumn>
                            <InputField
                                label="Name of Submitter/Contact:"
                                type="text"
                                name="contact"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                            <InputField
                                label="Date:"
                                type="date"
                                name="date"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                            <InputField
                                label="What company are you with?"
                                type="text"
                                name="company"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />

                            <InputField
                                label="Best contact phone number:"
                                type="text"
                                name="phoneNumber"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />

                            <InputField
                                label="Best contact email address?"
                                type="text"
                                name="email"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                        </TwoColumn>
                    </OrdersContainer>
                    <OrdersContainer>
                        <h2>Testing/Analysis Info</h2>
                        <InputField
                            label="Project Name:"
                            type="text"
                            name="projectName"
                            register={register}
                            watch={watch}
                            required={true}
                            wide
                            submitAttempts={submitAttempts}
                        />
                        {Array.from({ length: scheduleNameCount }).map((_, i) => (
                            <InputField
                                label={`Schedule Name ${i != 0 ? i + 1 : ""}:`}
                                type="text"
                                name={`scheduleName${i + 1}`}
                                register={register}
                                watch={watch}
                                wide
                            />
                        ))}
                        <AddScheduleButton type="button" onClick={() => {
                            console.log(watch());
                            setScheduleNameCount(scheduleNameCount + 1)

                        }}>Add Another Schedule Name</AddScheduleButton>
                        {scheduleNameCount > 1 && <RemoveScheduleButton type="button" onClick={() => setScheduleNameCount(scheduleNameCount - 1)}>Remove Last Schedule Name</RemoveScheduleButton>}
                        <TwoColumn>
                            <RadioField
                                label="Is this analysis for drinking water?"
                                name="drinkingWater"
                                options={["Yes", "No"]}
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                            <RadioField
                                label="Will the samples be filtered in the field?"
                                name="filtered"
                                options={["Yes", "No"]}
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                        </TwoColumn>
                        {watch().drinkingWater === 'Yes' && (
                            <InputField
                                label="What is your PWS ID#?"
                                type="text"
                                name="PWS"
                                register={register}
                                watch={watch}
                                required={watch().drinkingWater === 'Yes'}
                                submitAttempts={submitAttempts}
                            />
                        )}
                        <TwoColumn>
                            <RadioField
                                label="Do you have specific requested reporting needs?"
                                name="reportingLimits"
                                options={["Yes", "No"]}
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                            <RadioField
                                label="Do you need tests run by a specific method?"
                                name="method"
                                options={["Yes", "No"]}
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                        </TwoColumn>

                        <SelectField
                            label={"What Data Validation/ QC Level package is needed?"}
                            name={"dataValidation"}
                            options={[
                                "Level 1 / DOD Stage 1",
                                "Level 2",
                                "Level 3 / DOD Stage 2A",
                                "Level 4",
                                "DOD Stage 2B",
                                "Dod Stage 3/4"
                            ]}
                            register={register}
                            watch={watch}
                            required={true}
                            submitAttempts={submitAttempts}
                        />
                        <p>For additional information on QC Levels check <a href={process.env.REACT_APP_APILink + '/api/Links/Content/QC%20Package%20Level.pdf'} target='_blank' >our graphic here</a></p>

                        <Label>Please list individual parameter needs:</Label>

                        <ResizeableInputField
                            name="parameterNeeds"
                            placeholder="Parameter needs..."
                            control={control}
                        />

                    </OrdersContainer>
                    <OrdersContainer>
                        <h2>Shipping/Receiving Information</h2>
                        <InputField
                            label="By what date do you need to receive the order?"
                            type="date"
                            name="receiveDate"
                            register={register}
                            watch={watch}
                            required={true}
                            fullInput={false}
                            submitAttempts={submitAttempts}
                        />
                        <RadioField
                            label="Which carrier would you like to receive the supplies via?"
                            name="receiveCarrier"
                            options={["Fedex", "UPS", "NPT", "Courier", "Lab Pickup", "Other"]}
                            register={register}
                            watch={watch}
                            required={true}
                            submitAttempts={submitAttempts}
                        />
                        {/* start of DoNotShow section if receiveCarrier is Lab Pickup */}
                        {watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null && (
                            <>
                                <RadioField
                                    label="Which service level (speed) do you need to receive the supplies?"
                                    name="receiveServiceLevel"
                                    options={["Expedited", "Regular Ground", "Saturday Delivery"]}
                                    register={register}
                                    watch={watch}
                                    required={(watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null)}
                                    submitAttempts={submitAttempts}
                                />
                                <InputField
                                    label="Name:"
                                    type="text"
                                    name="addressName"
                                    register={register}
                                    watch={watch}
                                    required={(watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null)}
                                    submitAttempts={submitAttempts}
                                />
                                <TwoColumn>
                                    <InputField
                                        label="Street Address 1:"
                                        type="text"
                                        name="addressStreet1"
                                        register={register}
                                        watch={watch}
                                        required={(watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null)}
                                        submitAttempts={submitAttempts}
                                    />
                                    <InputField
                                        label="Street Address 2:"
                                        type="text"
                                        name="addressStreet2"
                                        register={register}
                                        watch={watch}
                                        submitAttempts={submitAttempts}
                                    />
                                </TwoColumn>
                                <ThreeColumn>
                                    <InputField
                                        label="City:"
                                        type="text"
                                        name="addressCity"
                                        register={register}
                                        watch={watch}
                                        required={(watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null)}
                                        submitAttempts={submitAttempts}
                                    />
                                    <InputField
                                        label="State:"
                                        type="text"
                                        name="addressState"
                                        register={register}
                                        watch={watch}
                                        required={(watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null)}
                                        submitAttempts={submitAttempts}
                                    />
                                    <InputField
                                        label="Zip:"
                                        type="text"
                                        name="addressZip"
                                        register={register}
                                        watch={watch}
                                        required={(watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null)}
                                        submitAttempts={submitAttempts}
                                    />
                                </ThreeColumn>
                                <RadioField
                                    label="Is this address residential?"
                                    name="isResidential"
                                    options={["Yes", "No"]}
                                    register={register}
                                    watch={watch}
                                    required={(watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null)}
                                    wide
                                    submitAttempts={submitAttempts}
                                />
                            </>
                        )}
                        {/* end of DoNotShow section if receiveCarrier is Lab Pickup */}
                        <RadioField
                            label="By which carrier do you plan to send samples back to the lab?"
                            name="sendCarrier"
                            options={["Fedex", "UPS", "NPT", "Courier", "Lab Dropoff", "Other"]}
                            register={register}
                            watch={watch}
                            required={true}
                            submitAttempts={submitAttempts}
                        />
                        {/* Start of DoNotShow section if sendCarrier is Lab Dropoff */}
                        {watch().sendCarrier !== 'Lab Dropoff' && watch().sendCarrier != null && (
                            <>
                                <RadioField
                                    label="Which service level do you plan to return samples?"
                                    name="sendServiceLevel"
                                    options={["Expedited", "Regular Ground", "Saturday Delivery"]}
                                    register={register}
                                    watch={watch}
                                    required={(watch().sendCarrier !== 'Lab Dropoff' && watch().sendCarrier != null)}
                                    submitAttempts={submitAttempts}
                                />
                                <RadioField
                                    label="Do you need Energy Labs to send you a return label which we will charge you for or will you pay for your own service?"
                                    name="needReturnLabel"
                                    options={["Yes", "No"]}
                                    register={register}
                                    watch={watch}
                                    required={(watch().sendCarrier !== 'Lab Dropoff' && watch().sendCarrier != null)}
                                    submitAttempts={submitAttempts}
                                />
                                <RadioField
                                    label="Will the samples be flying at any time during the shipping?"
                                    name="isFlying"
                                    options={["Yes", "No"]}
                                    register={register}
                                    watch={watch}
                                    required={(watch().sendCarrier !== 'Lab Dropoff' && watch().sendCarrier != null)}
                                    wide
                                    submitAttempts={submitAttempts}
                                />
                            </>
                        )}
                        {/* End of DoNotShow section if sendCarrier is Lab Dropoff */}
                        <InputField
                            label="What date or approximate date do you plan to send the samples back to the lab?"
                            type="date"
                            name="returnDate"
                            register={register}
                            watch={watch}
                            required={true}
                            fullInput={false}
                            submitAttempts={submitAttempts}
                        />
                    </OrdersContainer>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px',
                        width: '100%'
                    }}>
                        <SubmitButton type="submit" onClick={() => {
                            setSubmitAttempts(submitAttempts + 1);
                        }}>Submit</SubmitButton>
                    </div>
                </form>
            </DataContainer>
        </Container>
    );
};

export const NewOrder = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitAttempts, setSubmitAttempts] = useState(0);
    const [locations, setLocations] = useState([]);

    const NoLocationRequirementOption = "No Requirement";

    useEffect(() => {
       
        fetch(process.env.REACT_APP_APILink + "/api/Locations")
            .then(response => response.json())
            .then(responseJson => setLocations(responseJson));
    }, []);

    const { register, handleSubmit, watch, formState: { errors }, control } = useForm({
        defaultValues: {
            date: new Date().toISOString().split('T')[0],
        },
    });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCAPTCHASubmit = useCallback(async (form_data) => {
        if (!process.env.REACT_APP_CAPTCHA_SITE_KEY) {

            onSubmit(form_data);
            return;
        }

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');
        fetch(process.env.REACT_APP_APILink + '/api/Captcha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ token: token })
        }).then(data => {
            if (data.status === 200)
                onSubmit(form_data);
            else {
                alert('Error submitting CAPTCHA. Please confirm your humanity and try again.');
            }
        }).catch(err => {
            alert('Error submitting form. Please try again later.');
        });
    }, [executeRecaptcha]);

    const onSubmit = async (data) => {
        const from = 'no-reply@energylab.com';
        const subject = 'New Bottle Order - ' + data.contact;
        const body = newOrderEmailTemplate(data);
        var to = null;

        if (data.location !== NoLocationRequirementOption) {
            var location = await (await fetch(`${process.env.REACT_APP_APILink}/api/Locations/${data.location}`)).json();

            data.location = location.name;
            to = location.orderEmail;
        }

        const email = {
            from: from,
            subject: subject,
            body: body,
            to: to
        };

        SendEmail(email);
    };

    const SendEmail = (message) => {
        fetch(process.env.REACT_APP_APILink + '/api/Email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        }).then(() => {
            setFormSubmitted(true);
        }).catch(err => {
            alert('Error submitting form. Please try again later.');
        });
    };

    if (formSubmitted) {
        return <ThankYou />;
    }

    return (
        <Container>
            <DataContainer>
                <h1 className="montserrat-regular"><b>New Order</b></h1>
                <form id="bottleOrderForm" onSubmit={handleSubmit(handleReCAPTCHASubmit)}>
                    <OrdersContainer>
                        <h2>Client Information</h2>
                        <TwoColumn>
                            <InputField
                                label="Name of Submitter/Contact:"
                                type="text"
                                name="contact"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                            <InputField
                                label="Date:"
                                type="date"
                                name="date"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                            <InputField
                                label="What company are you with?"
                                type="text"
                                name="company"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                            <InputField
                                label="Best contact phone number:"
                                type="text"
                                name="phoneNumber"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />

                            <InputField
                                label="Best contact email address?"
                                type="text"
                                name="email"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                        </TwoColumn>
                        <RadioField
                            label="Do you require your work be done specifically at one of our locations?"
                            name={"location"}
                            options={locations.map(l => ({ key: l.id, value: l.name })).concat([NoLocationRequirementOption])}
                            register={register}
                            watch={watch}
                            required={true}
                            submitAttempts={submitAttempts}
                        />
                        <RadioField
                            label="Do you require a data validation package other than standard?"
                            name="dataValidation"
                            options={["Yes", "No"]}
                            register={register}
                            watch={watch}
                            required={true}
                            submitAttempts={submitAttempts}
                            wide
                        />
                    </OrdersContainer>
                    <OrdersContainer>
                        <h2>Testing/Analysis Info</h2>
                        <InputField
                            label="Project Name:"
                            type="text"
                            name="projectName"
                            register={register}
                            watch={watch}
                            required={true}
                            submitAttempts={submitAttempts}
                            wide
                        />
                        <TwoColumn>
                            <RadioField
                                label="Is this analysis for drinking water?"
                                name="drinkingWater"
                                options={["Yes", "No"]}
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                            <RadioField
                                label="Will the samples be filtered in the field?"
                                name="filtered"
                                options={["Yes", "No"]}
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                        </TwoColumn>
                        {watch().drinkingWater === 'Yes' && (
                            <InputField
                                label="What is your PWS ID#?"
                                type="text"
                                name="PWS"
                                register={register}
                                watch={watch}
                                required={watch().drinkingWater === 'Yes'}
                                submitAttempts={submitAttempts}
                            />
                        )}
                        <TwoColumn>
                            <RadioField
                                label="Do you have specific requested reporting needs?"
                                name="reportingLimits"
                                options={["Yes - Contact a PM to ask for a quote", "No"]}
                                register={register}
                                watch={watch}
                                required={true}
                                horizontalInputs={false}
                                submitAttempts={submitAttempts}
                            />
                            <RadioField
                                label="Do you need tests run by a specific method?"
                                name="method"
                                options={["Yes - Contact a PM to ask for a quote", "No"]}
                                register={register}
                                watch={watch}
                                required={true}
                                horizontalInputs={false}
                                submitAttempts={submitAttempts}
                            />
                        </TwoColumn>

                        <Label>Please list individual parameter needs:</Label>

                        <ResizeableInputField
                            name="parameterNeeds"
                            placeholder="Parameter needs..."
                            control={control}
                        />

                    </OrdersContainer>
                    <OrdersContainer>
                        <h2>Quote Information</h2>
                        <RadioField
                            label="Is there a quote number already established for the order?"
                            name="hasQuoteNumber"
                            options={["Yes", "No"]}
                            register={register}
                            watch={watch}
                            required={true}
                            wide
                            submitAttempts={submitAttempts}
                        />
                        {watch().hasQuoteNumber === 'Yes' && (
                            <InputField
                                label="Quote#"
                                type="text"
                                name="quoteNumber"
                                register={register}
                                watch={watch}
                                required={watch().hasQuoteNumber === 'Yes'}
                                submitAttempts={submitAttempts}
                            />
                        )}
                    </OrdersContainer>
                    <OrdersContainer>
                        <h2>Shipping/Receiving Information</h2>
                        <InputField
                            label="By what date do you need to receive the order?"
                            type="date"
                            name="receiveDate"
                            register={register}
                            watch={watch}
                            required={true}
                            fullInput={false}
                            submitAttempts={submitAttempts}
                        />
                        <RadioField
                            label="Which carrier would you like to receive the supplies via?"
                            name="receiveCarrier"
                            options={["Fedex", "UPS", "NPT", "Courier", "Lab Pickup", "Other"]}
                            register={register}
                            watch={watch}
                            required={true}
                            submitAttempts={submitAttempts}
                        />
                        {/* start of DoNotShow section if receiveCarrier is Lab Pickup */}
                        {watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null && (
                            <>
                                <RadioField
                                    label="Which service level (speed) do you need to receive the supplies?"
                                    name="receiveServiceLevel"
                                    options={["Expedited", "Regular Ground", "Saturday Delivery"]}
                                    register={register}
                                    watch={watch}
                                    required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                    submitAttempts={submitAttempts}
                                />
                                <InputField
                                    label="Name:"
                                    type="text"
                                    name="addressName"
                                    register={register}
                                    watch={watch}
                                    required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                    submitAttempts={submitAttempts}
                                />
                                <TwoColumn>
                                    <InputField
                                        label="Street Address 1:"
                                        type="text"
                                        name="addressStreet1"
                                        register={register}
                                        watch={watch}
                                        required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                        submitAttempts={submitAttempts}
                                    />
                                    <InputField
                                        label="Street Address 2:"
                                        type="text"
                                        name="addressStreet2"
                                        register={register}
                                    />
                                </TwoColumn>
                                <ThreeColumn>
                                    <InputField
                                        label="City:"
                                        type="text"
                                        name="addressCity"
                                        register={register}
                                        watch={watch}
                                        required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                        submitAttempts={submitAttempts}
                                    />
                                    <InputField
                                        label="State:"
                                        type="text"
                                        name="addressState"
                                        register={register}
                                        watch={watch}
                                        required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                        submitAttempts={submitAttempts}
                                    />
                                    <InputField
                                        label="Zip:"
                                        type="text"
                                        name="addressZip"
                                        register={register}
                                        watch={watch}
                                        required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                        submitAttempts={submitAttempts}
                                    />
                                </ThreeColumn>
                                <RadioField
                                    label="Is this address residential?"
                                    name="isResidential"
                                    options={["Yes", "No"]}
                                    register={register}
                                    watch={watch}
                                    required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                    submitAttempts={submitAttempts}
                                    wide
                                />
                            </>
                        )}
                        {/* end of DoNotShow section if receiveCarrier is Lab Pickup */}
                        <RadioField
                            label="By which carrier do you plan to send samples back to the lab?"
                            name="sendCarrier"
                            options={["Fedex", "UPS", "NPT", "Courier", "Lab Dropoff", "Other"]}
                            register={register}
                            watch={watch}
                            required={true}
                            submitAttempts={submitAttempts}
                        />
                        {/* Start of DoNotShow section if sendCarrier is Lab Dropoff */}
                        {watch().sendCarrier !== 'Lab Dropoff' && watch().sendCarrier != null && (
                            <>
                                <RadioField
                                    label="Which service level do you plan to return samples?"
                                    name="sendServiceLevel"
                                    options={["Expedited", "Regular Ground", "Saturday Delivery"]}
                                    register={register}
                                    watch={watch}
                                    required={watch().sendCarrier !== 'Lab Dropoff' && watch().sendCarrier != null}
                                    submitAttempts={submitAttempts}
                                />
                                <RadioField
                                    label="Do you need Energy Labs to send you a return label which we will charge you for or will you pay for your own service?"
                                    name="needReturnLabel"
                                    options={["Yes", "No"]}
                                    register={register}
                                    watch={watch}
                                    required={watch().sendCarrier !== 'Lab Dropoff' && watch().sendCarrier != null}
                                    submitAttempts={submitAttempts}
                                />
                                <RadioField
                                    label="Will the samples be flying at any time during the shipping?"
                                    name="isFlying"
                                    options={["Yes", "No"]}
                                    register={register}
                                    watch={watch}
                                    required={watch().sendCarrier !== 'Lab Dropoff' && watch().sendCarrier != null}
                                    wide
                                    submitAttempts={submitAttempts}
                                />
                            </>
                        )}
                        {/* End of DoNotShow section if sendCarrier is Lab Dropoff */}
                        <InputField
                            label="What date or approximate date do you plan to send the samples back to the lab?"
                            type="date"
                            name="returnDate"
                            register={register}
                            watch={watch}
                            required={true}
                            fullInput={false}
                            submitAttempts={submitAttempts}
                        />
                    </OrdersContainer>
                    <MobileAdditionalInfoContainer>
                        <h2>Additional Information</h2>
                        <OrdersContainer>
                            <p>
                                The following pieces of information may be helpful to ensure a complete order. If applicable, please have available as you work with a Project Manager on your first order:
                            </p>
                            <ul>
                                <li>Testing Dates and Turn-Around-Time requests</li>
                                <li>Certification Requirements</li>
                                <li>Permit Requirements</li>
                                <li>PWS ID if Drinking Water is requested</li>
                                <li>Existing Quotes</li>
                                <li>Who the financial responsible party for results will be</li>
                                <li>Any Purchase Order information needed on the order</li>
                                <li>Special Reporting Formats</li>
                                <li>Distribution lists for reports and invoices</li>
                                <li>Shipping requirements (service level, return information) & addresses</li>
                            </ul>
                            <ResizeableInputField
                                name="additionalInformation"
                                placeholder="Additional Information..."
                                control={control}
                            />
                        </OrdersContainer>
                    </MobileAdditionalInfoContainer>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px',
                        width: '100%'
                    }}>
                        <SubmitButton type="submit" onClick={() => {
                            setSubmitAttempts(submitAttempts + 1);
                        }}>Submit</SubmitButton>
                    </div>
                </form>
            </DataContainer>
            <StickyRunway>
                <AdditionalInfoContainer>
                    <h2>Additional Information</h2>
                    <OrdersContainer>
                        <p>
                            The following pieces of information may be helpful to ensure a complete order. If applicable, please have available as you work with a Project Manager on your first order:
                        </p>
                        <ul>
                            <li>Testing Dates and Turn-Around-Time requests</li>
                            <li>Certification Requirements</li>
                            <li>Permit Requirements</li>
                            <li>PWS ID if Drinking Water is requested</li>
                            <li>Existing Quotes</li>
                            <li>Who the financial responsible party for results will be</li>
                            <li>Any Purchase Order information needed on the order</li>
                            <li>Special Reporting Formats</li>
                            <li>Distribution lists for reports and invoices</li>
                            <li>Shipping requirements (service level, return information) & addresses</li>
                        </ul>
                        <ResizeableInputField
                            name="additionalInformation"
                            placeholder="Additional Information..."
                            control={control}
                        />
                    </OrdersContainer>
                </AdditionalInfoContainer>
            </StickyRunway>
        </Container>
    );
}

export const RepeatOrder = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitAttempts, setSubmitAttempts] = useState(0);
    const [supportEmail, setSupportEmail] = useState('');

    useEffect(() => {
        fetch(process.env.REACT_APP_APILink + "/api/WebsiteConfig", {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                setSupportEmail(data["supportEmail"]);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            date: new Date().toISOString().split('T')[0],
        },
    });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCAPTCHASubmit = useCallback(async (form_data) => {
        if (!process.env.REACT_APP_CAPTCHA_SITE_KEY) {

            onSubmit(form_data);
            return;
        }

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');
        fetch(process.env.REACT_APP_APILink + '/api/Captcha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ token: token })
        }).then(data => {
            if (data.status === 200)
                onSubmit(form_data);
            else {
                alert('Error submitting CAPTCHA. Please confirm your humanity and try again.');
            }
        }).catch(err => {
            alert('Error submitting form. Please try again later.');
        });
    }, [executeRecaptcha]);

    const onSubmit = async (data) => {
        const body = repeatOrderEmailTemplate(data);

        const from = 'no-reply@energylab.com';
        const subject = `Repeat Bottle Order Form - ${data.contact}`;
        const to = supportEmail;

        try {
            await SendEmail({ to, from, subject, body });
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    const SendEmail = async ({ to, from, subject, body }) => {
        fetch(process.env.REACT_APP_APILink + '/api/Email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ to, from, subject, body })
        }).then(() => {
            setFormSubmitted(true);
        }).catch(err => {
            alert('Error submitting form. Please try again later.');
        });
    };

    if (formSubmitted) {
        return <ThankYou />;
    }

    return (
        <Container>
            <DataContainer>
                <h1 className="montserrat-regular"><b>Repeat Order</b></h1>
                <form id="bottleOrderForm" onSubmit={handleSubmit(handleReCAPTCHASubmit)}>
                    <OrdersContainer>
                        <h2>Client Information</h2>
                        <TwoColumn>
                            <InputField
                                label="Name of Submitter/Contact"
                                type="text"
                                name="contact"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                            <InputField
                                label="Date"
                                type="date"
                                name="date"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                            <InputField
                                label="What company are you with?"
                                type="text"
                                name="company"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                            <InputField
                                label="Best contact phone number"
                                type="text"
                                name="phoneNumber"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />

                            <InputField
                                label="Best contact email address?"
                                type="text"
                                name="email"
                                register={register}
                                watch={watch}
                                required={true}
                                submitAttempts={submitAttempts}
                            />
                        </TwoColumn>
                    </OrdersContainer>
                    <OrdersContainer>
                        <h2>Order Identifier</h2>
                        <InputField
                            label="Provide the Bottle Order Number, Workorder Number, Quote Number or Schedule Name you would like to repeat:"
                            type="text"
                            register={register}
                            watch={watch}
                            name="previousIdentifier"
                            required={true}
                            submitAttempts={submitAttempts}
                        />
                    </OrdersContainer>
                    <OrdersContainer>
                        <h2>Shipping/Receiving Information</h2>
                        <InputField
                            label="By what date do you need to receive the order?"
                            type="date"
                            name="receiveDate"
                            register={register}
                            watch={watch}
                            required={true}
                            fullInput={false}
                            submitAttempts={submitAttempts}
                        />
                        <RadioField
                            label="Which carrier would you like to receive the supplies via?"
                            name="receiveCarrier"
                            options={["Fedex", "UPS", "NPT", "Courier", "Lab Pickup", "Other"]}
                            register={register}
                            watch={watch}
                            required={true}
                            submitAttempts={submitAttempts}
                        />
                        {/* start of DoNotShow section if receiveCarrier is Lab Pickup */}
                        {watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null && (
                            <>
                                <RadioField
                                    label="Which service level (speed) do you need to receive the supplies?"
                                    name="receiveServiceLevel"
                                    options={["Expedited", "Regular Ground", "Saturday Delivery"]}
                                    register={register}
                                    watch={watch}
                                    required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                    submitAttempts={submitAttempts}
                                />
                                <InputField
                                    label="Name"
                                    type="text"
                                    name="addressName"
                                    register={register}
                                    watch={watch}
                                    required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                    submitAttempts={submitAttempts}
                                />
                                <TwoColumn>
                                    <InputField
                                        label="Street Address 1"
                                        type="text"
                                        name="addressStreet1"
                                        register={register}
                                        watch={watch}
                                        required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                        submitAttempts={submitAttempts}
                                    />
                                    <InputField
                                        label="Street Address 2"
                                        type="text"
                                        name="addressStreet2"
                                        register={register}
                                    />
                                </TwoColumn>
                                <ThreeColumn>
                                    <InputField
                                        label="City"
                                        type="text"
                                        name="addressCity"
                                        register={register}
                                        watch={watch}
                                        required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                        submitAttempts={submitAttempts}
                                    />
                                    <InputField
                                        label="State"
                                        type="text"
                                        name="addressState"
                                        register={register}
                                        watch={watch}
                                        required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                        submitAttempts={submitAttempts}
                                    />
                                    <InputField
                                        label="Zip"
                                        type="text"
                                        name="addressZip"
                                        register={register}
                                        watch={watch}
                                        required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                        submitAttempts={submitAttempts}
                                    />
                                </ThreeColumn>
                                <RadioField
                                    label="Is this address residential?"
                                    name="isResidential"
                                    options={["Yes", "No"]}
                                    register={register}
                                    watch={watch}
                                    required={watch().receiveCarrier !== 'Lab Pickup' && watch().receiveCarrier != null}
                                    wide
                                    submitAttempts={submitAttempts}
                                />
                            </>
                        )}
                        {/* end of DoNotShow section if receiveCarrier is Lab Pickup */}
                        <RadioField
                            label="By which carrier do you plan to send samples back to the lab?"
                            name="sendCarrier"
                            options={["Fedex", "UPS", "NPT", "Courier", "Lab Dropoff", "Other"]}
                            register={register}
                            watch={watch}
                            required={true}
                            submitAttempts={submitAttempts}
                        />
                        {/* Start of DoNotShow section if sendCarrier is Lab Dropoff */}
                        {watch().sendCarrier !== 'Lab Dropoff' && watch().sendCarrier != null && (
                            <>
                                <RadioField
                                    label="Which service level do you plan to return samples?"
                                    name="sendServiceLevel"
                                    options={["Expedited", "Regular Ground", "Saturday Delivery"]}
                                    register={register}
                                    watch={watch}
                                    required={true}
                                    submitAttempts={submitAttempts}
                                />
                                <RadioField
                                    label="Do you need Energy Labs to send you a return label which we will charge you for or will you pay for your own service?"
                                    name="needReturnLabel"
                                    options={["Yes", "No"]}
                                    register={register}
                                    watch={watch}
                                    required={true}
                                    submitAttempts={submitAttempts}
                                />
                                <RadioField
                                    label="Will the samples be flying at any time during the shipping?"
                                    name="isFlying"
                                    options={["Yes", "No"]}
                                    register={register}
                                    watch={watch}
                                    required={true}
                                    wide
                                    submitAttempts={submitAttempts}
                                />
                            </>
                        )}
                        {/* End of DoNotShow section if sendCarrier is Lab Dropoff */}
                        <InputField
                            label="What date or approximate date do you plan to send the samples back to the lab?"
                            type="date"
                            name="returnDate"
                            register={register}
                            watch={watch}
                            required={true}
                            fullInput={false}
                            submitAttempts={submitAttempts}
                        />
                    </OrdersContainer>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px',
                        width: '100%'
                    }}>
                        <SubmitButton type="submit" onClick={() => {
                            setSubmitAttempts(submitAttempts + 1);
                        }}>Submit</SubmitButton>
                    </div>
                </form>
            </DataContainer>
        </Container>
    );
};